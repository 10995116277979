<template>
	<div class="page-web page_legal">
		<Head :MyLocal="location" @newLocal="location=$event" />
		<div class="banner-curr flex flex-ac flex-hc whole_000">
			<p>{{$t('crTxt')}}</p>
			<span>{{$t('crTxt2')}}</span>
		</div>
		<div class="index-tab legal_tab">
			<div class="legal_line"></div>
			<div class="legal_bj"></div>
			<div class="container">
				<div class="legal_sybmol">
					<el-button type="primary" class="btn" :class="[current == index?'btn_mc':'']"
						v-for="(item,index) in btns" :key="index" @click="tradeClick(index,item.unit)">
						{{$t(item.unit)}}
					</el-button>
				</div>
				<div class="exte-box legal_box">
					<div class="legal_r">
						<div>
							<el-select v-model="country" placeholder="" @change="change">
								<el-option v-for="(item,index) in options" :key="index" :label="location == 'zh_CN' ? item.zhName: item.enName"
									:value="item.zhName">
								</el-option>`
							</el-select>
						</div>
					</div>
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="$t('dhTxt4')" name="1">
							<div class="bz-table legal_table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column width="180" prop="memberName" :label="$t('crth1')">
									</el-table-column>
									<el-table-column width="170" prop="transactions" :label="$t('crth2')">
									</el-table-column>
									<el-table-column width="170" prop="payMode" :label="$t('crth3')">
									</el-table-column>
									<el-table-column width="170" prop="remainAmount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column width="170" :label="$t('crth4')">
										<template slot-scope="scope">
											<div>{{scope.row.minLimit}}-{{scope.row.maxLimit}} <span>{{coin}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="170" prop="price" :label="$t('crth5')">
										<template slot-scope="scope">
											<p class="cell_p">{{scope.row.price}} <span>{{coin}}</span></p>
										</template>
									</el-table-column>
									<el-table-column width="170" prop="operation" :label="$t('th5')">
										<template slot-scope="scope">
											<el-button type="text" class="btn" @click="buyDetail(scope.row)">
												{{$t('dhTxt4')}} <span v-if="current == 0">USDT</span>
												<span v-if="current == 1">BTC</span>
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :total="total" :background="true"></el-pagination>
						</el-tab-pane>
						<el-tab-pane :label="$t('dhTxt3')" name="0">
							<div class="bz-table legal_table">
								<el-table :data="tableData1" style="width: 100%">
									<el-table-column width="180" prop="memberName" :label="$t('crth1')">
									</el-table-column>
									<el-table-column width="170" prop="transactions" :label="$t('crth2')">
									</el-table-column>
									<el-table-column width="170" prop="payMode" :label="$t('crth3')">
									</el-table-column>
									<el-table-column width="170" prop="remainAmount" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column width="170" :label="$t('crth4')">
										<template slot-scope="scope">
											<div>{{scope.row.minLimit}}-{{scope.row.maxLimit}} <span>{{coin}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="170" prop="price" :label="$t('crth5')">
										<template slot-scope="scope">
											<p class="cell_p">{{scope.row.price}}<span>{{coin}}</span></p>
										</template>
									</el-table-column>
									<el-table-column width="170" prop="operation" :label="$t('th5')">
										<template slot-scope="scope">
											<el-button type="text" class="btn" @click="sellDetail(scope.row)">{{$t('dhTxt4')}}
												<span v-if="current == 0">USDT</span>
												<span v-if="current == 1">BTC</span>
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<el-pagination @current-change="handleCurrentChange2" :current-page.sync="currentPage2"
								layout="prev, pager, next" :total="total2" :background="true"></el-pagination>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="legal_grid exte-box">
					<div class="legal_list">
						<el-row :gutter="50">
							<el-col :lg="6" :md="12" :sm="12" :xs="12" v-for="(item,index) in LgealList" :key="index">
								<div class="legal_cell">
									<div class="legal_cell_pic">
										<img :src="item.pic" alt="">
									</div>
									<div class="legal_cell_name">{{$t(item.name)}}</div>
									<div class="legal_cell_desc line-2">{{$t(item.info)}}</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="exp-btn">
						<el-button type="primary" class="btn btn_b btn_sj" @click="toShangjia">{{$t('crbtn2')}}
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		getCountry
	} from '@/api/api/user'
	import {
		pageByUnit,
		coinAll
	} from '@/api/api/otc'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				activeName: '1',
				options: [],
				tableData: [],
				country: '美国',
				tableData1: [],
				LgealList: [{
						pic: require("../../assets/photo/web/bi-icon1.png"),
						name: "crTxt3",
						info: "crTxt7",
					},
					{
						pic: require("../../assets/photo/web/bi-icon4.png"),
						name: "crTxt4",
						info: "crTxt8",
					},
					{
						pic: require("../../assets/photo/web/bi-icon3.png"),
						name: "crTxt5",
						info: "crTxt9",
					},
					{
						pic: require("../../assets/photo/web/bi-icon2.png"),
						name: "crTxt6",
						info: "crTxt10",
					}
				],
				location: "en_US",
				btns: [],
				current: 0,
				currentPage: 1,
				pageSize: 10,
				total: 10,
				currentPage2: 1,
				pageSize2: 10,
				total2: 10,
				unit: 'USDT',
				coin: 'USD'
			};
		},
		created() {
			this.location = localStorage.getItem('lang')
		},
		mounted() {
			this.countryList()
			this.coinList()
		},
		computed: {
			isLogin: function() {
				return this.$store.getters.isLogin
			},
			member: function() {
				return this.$store.getters.member
			},
			lang: function() {
				return this.$store.state.lang
			},
		},
		methods: {
			// tab
			coinList() {
				coinAll().then(res => {
					if (res.code == 0) {
						this.btns = res.data
						this.unit = res.data[this.current].unit
						this.$router.push({
							path: '/trade/' + this.unit,
						})
						this.$nextTick(() => {
							this.getList()
						})
					}
				})
			},
			handleClick() {
				this.getList()
			},
			countryList() {
				getCountry().then(res => {
					if (res.code == 0) {
						this.options = res.data
					}
				})
			},
			async getList() {
				if (this.activeName == '1') {
					const res = await pageByUnit({
						unit: this.unit,
						advertiseType: Number(this.activeName),
						isCertified: 0,
						country: this.country,
						pageNo: this.currentPage,
						pageSize: this.pageSize
					})
					if (res.code == 0) {
						console.log(res)
						this.tableData = res.data.context
						this.total = res.data.totalElement
					}
				}
				if (this.activeName == '0') {
					const res = await pageByUnit({
						unit: this.unit,
						advertiseType: Number(this.activeName),
						isCertified: 0,
						country: this.country,
						pageNo: this.currentPage2,
						pageSize: this.pageSize2
					})
					if (res.code == 0) {
						this.tableData1 = res.data.context
						this.total2 = res.data.totalElement
					}
				}

			},
			tradeClick(index, val) {
				console.log(index, val)
				this.current = index
				this.unit = val
				this.$router.push({
					path: '/trade/' + val,
				})
				this.getList()

			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val
				this.getList()
			},
			change(value) {
        let obj = this.options.filter(item => {
          return item.zhName == value
        })
        this.coin = obj[0].localCurrency
				this.getList()
			},
			toShangjia() {
				let token = localStorage.getItem('token')
				if (token) {
					this.$router.push('/identbusiness')
				} else {
					this.$message(this.$t('loginFirst'));
				}
			},
			buyDetail(row) {
				if (!this.isLogin) {
					this.$router.push('/login')
				} else if (!this.member.realName) {
					this.$message.error(this.$t('otc.validate'))
					setTimeout(() => {
						this.$router.push('/admin')
					}, 2000)
				} else {
					this.$router.push('/tradeInfo?tradeId=' + row.advertiseId)
				}
			},
			sellDetail(row){
				if (!this.isLogin) {
					this.$router.push('/login')
				} else if (!this.member.realName) {
					this.$message.error(this.$t('otc.validate'))
					setTimeout(() => {
						this.$router.push('/admin')
					}, 2000)
				} else {
					this.$router.push('/tradeInfo?tradeId=' + row.advertiseId)
				}
			}
		}
	}
</script>
